import React, { useEffect, useState } from 'react';
import authFetch from '../services/authFetch'; // Certifique-se de usar o authFetch para a requisição autenticada
import '../styles/ApisPage.css';  // Estilo da página (criaremos o CSS em seguida)

function APIsPage() {
  const [endpoints, setEndpoints] = useState([]); // Armazena os endpoints da API
  const [error, setError] = useState(null);

  // Função para buscar os endpoints da API
  useEffect(() => {
    const fetchEndpoints = async () => {
      try {
        const response = await authFetch('https://homolog-panel.zepesquisa.com.br/api/list-endpoints/');
        if (!response.ok) {
          throw new Error('Erro ao buscar endpoints');
        }

        const result = await response.json();
        console.log('Retorno da API:', result); // Exibe o retorno da API no console
        setEndpoints(result.available_endpoints); // Armazena os endpoints no estado
      } catch (err) {
        console.error('Erro ao buscar endpoints:', err);
        setError(err.message);
      }
    };

    fetchEndpoints(); // Faz a requisição quando o componente é montado
  }, []);

  if (error) {
    return <div>Erro: {error}</div>;
  }

  return (
    <div className="apis-container">
      <h2>Documentação de Endpoints</h2>
      {endpoints.length > 0 ? (
        <ul>
          {endpoints.map((endpoint, index) => (
            <li key={index}>
              <p><strong>Endpoint {index + 1}:</strong> {endpoint}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>Carregando endpoints...</p>
      )}
    </div>
  );
}

export default APIsPage;
