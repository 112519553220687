import React, { useEffect, useState } from 'react';
import authFetch from '../services/authFetch'; // Vamos usar authFetch para a requisição
import '../styles/HomePage.css';  // Importa o CSS específico para a HomePage
import logo from '../assets/images/logo.jpg';  // Importa a imagem do logo
import RecargaPage from './RecargaPage';
import ConsultarPage from './ConsultarPage';
import ApisPage from './ApisPage';
import PainelPage from './PainelPage';

function HomePage() {
  const [users, setUsers] = useState([]); // Estado para armazenar os dados dos usuários
  const [error, setError] = useState(null);
  const [activeContent, setActiveContent] = useState(''); // Gerencia o conteúdo a ser exibido
  const [sidebarOpen, setSidebarOpen] = useState(false); // Estado para controlar a visibilidade do menu lateral

  // Função para buscar os usuários da API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await authFetch('https://homolog-panel.zepesquisa.com.br/accounts/users/');
        if (!response.ok) {
          throw new Error('Erro ao buscar usuários');
        }

        const result = await response.json();
        setUsers(result); // Armazena os usuários no estado
      } catch (err) {
        setError(err.message);
      }
    };

    fetchUsers(); // Faz a requisição quando o componente é montado
  }, []);

  // Função de logout
  const handleLogout = () => {
    // Limpa o token ou qualquer dado de autenticação
    localStorage.removeItem('token'); // Exemplo: remove o token de autenticação armazenado
    window.location.href = '/login'; // Redireciona para a página de login ou home
  };

  // Função para formatar o saldo como moeda
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  // Função para alternar a visibilidade da sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (error) {
    return <div>Erro: {error}</div>;
  }

  return (
    <>
      <div className={`home-page-container ${sidebarOpen ? 'sidebar-open' : ''}`}>
        {/* Botão para abrir a barra lateral no mobile */}
        <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
          {sidebarOpen ? 'Fechar Menu' : 'Abrir Menu'}
        </button>

        {/* Barra lateral com a imagem e botões verticais */}
        <div className={`home-sidebar ${sidebarOpen ? 'open' : ''}`}>
          <img src={logo} alt="Logo" className="sidebar-logo" />
          <button onClick={() => { setActiveContent('Consulta'); setSidebarOpen(false); }} className="home-consult">Consulta</button>
          <button onClick={() => { setActiveContent('Painel'); setSidebarOpen(false); }}>Painel</button>
          <button onClick={() => { setActiveContent('APIs'); setSidebarOpen(false); }}>APIs</button>
          <button onClick={() => { setActiveContent('Recarga'); setSidebarOpen(false); }}>Recarga</button>
        </div>

        {/* Barra de informações do usuário na parte superior ao lado da barra lateral */}
        <div className="main-content">
          <div className="user-info-horizontal">
            <div className="user-list">
              {users.length > 0 ? (
                <ul>
                  {users.map(user => (
                    <li key={user.id}>
                      <strong>Email:</strong> {user.email} <br />
                      <strong>Saldo:</strong> {formatCurrency(user.balance)} {/* Formata o saldo como moeda */}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Carregando usuários...</p>
              )}
            </div>
            
            {/* Botão de Logout no lado direito */}
            <button className="logout-button" onClick={handleLogout}>Logout</button>
          </div>

          {/* Conteúdo dinâmico renderizado abaixo da barra de informações */}
          <div className="dynamic-content">
            {activeContent === 'Painel' && <PainelPage />}
            {activeContent === 'APIs' && <ApisPage />}
            {activeContent === 'Recarga' && <RecargaPage />}
            {activeContent === 'Consulta' && <ConsultarPage />}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
