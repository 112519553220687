import React, { useState } from 'react';
import authFetch from '../services/authFetch';  // Certifique-se de importar o authFetch corretamente
import '../styles/RecargaPage.css';  // Importa o CSS específico para a página de Recarga

function RecargaPage() {
  const [value, setValue] = useState(''); // Estado para armazenar o valor da recarga formatado
  const [decimalValue, setDecimalValue] = useState(''); // Estado para armazenar o valor decimal
  const [pixCode, setPixCode] = useState(''); // Armazena o código PIX retornado
  const [qrCode, setQrCode] = useState(''); // Armazena o QR Code retornado
  const [error, setError] = useState(''); // Armazena qualquer erro
  const [loading, setLoading] = useState(false); // Indica se a requisição está em andamento

  // Função para copiar o código PIX para a área de transferência
  const copyToClipboard = () => {
    navigator.clipboard.writeText(pixCode);
    alert('Código PIX copiado para a área de transferência!');
  };

  // Função para formatar o valor conforme o usuário digita
  const formatCurrency = (inputValue) => {
    const cleanValue = inputValue.replace(/\D/g, ''); // Remove qualquer caractere não numérico
    const decimal = (Number(cleanValue) / 100).toFixed(2); // Converte para decimal

    // Atualiza o estado com o valor formatado e o decimal limpo para envio
    setValue(
      new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(decimal)
    );
    setDecimalValue(decimal);
  };

  // Função para enviar a requisição de recarga
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Inicia o estado de loading
    setError(''); // Reseta o erro antes de uma nova requisição

    try {
      // Substituímos fetch por authFetch e enviamos o valor decimal
      const response = await authFetch('https://homolog-panel.zepesquisa.com.br/api/recharges/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ valor: decimalValue }), // Envia o valor decimal da recarga
      });

      if (!response.ok) {
        throw new Error('Erro ao gerar PIX');
      }

      const result = await response.json();
      setPixCode(result.pix_code); // Armazena o código PIX retornado
      setQrCode(`data:image/png;base64,${result.qrcode_base64}`); // Formata o QR code base64 como imagem
    } catch (err) {
      setError(err.message); // Armazena o erro, se houver
    } finally {
      setLoading(false); // Finaliza o estado de loading
    }
  };

  // Função para lidar com o clique no botão "Voltar"
  const handleVoltar = () => {
    setPixCode('');
    setQrCode('');
    setValue('');
    setDecimalValue('');
    setError('');
  };

  return (
    <div className="recarga-container">
      <h2>Recarga</h2>

      {/* Exibe o formulário de recarga apenas se não houver código PIX ou QR Code */}
      {!pixCode && !qrCode && (
        <form onSubmit={handleSubmit} className="recarga-form">
          <label htmlFor="valor">Insira o valor da recarga:</label>
          <input
            type="text" // Alterado para "text" para suportar formatação de moeda
            id="valor"
            placeholder="R$ 0,00"
            value={value}
            onChange={(e) => formatCurrency(e.target.value)} // Chama a função de formatação
            required
          />
          <button type="submit" className="generate-pix-btn" disabled={loading}>
            {loading ? 'Aguarde...' : 'Gerar PIX'}
          </button>
        </form>
      )}

      {error && <div className="error-message">{error}</div>} {/* Exibe erros, se houver */}

      {/* Exibe o código PIX e o QR Code, se disponíveis */}
      {pixCode && qrCode && (
        <div className="pix-result">
          <div className="pix-code-box">
            <label>Código PIX:</label>
            <input type="text" value={pixCode} readOnly />
            <button onClick={copyToClipboard} className="copy-btn">Copiar Código</button>
          </div>

          {qrCode && (
            <div className="qrcode">
              <label>QR Code:</label>
              <img src={qrCode} alt="QR Code para pagamento" />
            </div>
          )}

          {/* Botão para voltar ao formulário */}
          <button onClick={handleVoltar} className="voltar-btn">
            Voltar
          </button>
        </div>
      )}
    </div>
  );
}

export default RecargaPage;
