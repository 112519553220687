import React, { useState, useEffect } from 'react';
import authFetch from '../services/authFetch';
import jsPDF from 'jspdf'; // Importa a biblioteca jsPDF
import 'jspdf-autotable'; // Importa o plugin de tabelas do jsPDF
import html2canvas from 'html2canvas'; // Importa html2canvas
import '../styles/ConsultarPage.css';

function ConsultarPage() {
  const [consultas, setConsultas] = useState([]);
  const [selectedConsulta, setSelectedConsulta] = useState('');
  const [query, setQuery] = useState('');
  const [queryParam, setQueryParam] = useState('');
  const [paramError, setParamError] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState(null);

  const specificEndpoint = '1-SERASA-ON';

  useEffect(() => {
    const fetchConsultas = async () => {
      try {
        const response = await authFetch('https://homolog-panel.zepesquisa.com.br/api/list-methods/');
        if (!response.ok) throw new Error('Erro ao buscar consultas');
        const data = await response.json();
        setConsultas(data.available_endpoints || []);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchConsultas();
  }, []);

  const fetchQueryParam = async (consultaSelecionada) => {
    try {
      setParamError(null);
      if (consultaSelecionada) {
        const response = await authFetch(`https://homolog-panel.zepesquisa.com.br/api/get-parameters/?endpoint=${consultaSelecionada}`);
        if (!response.ok) throw new Error('Erro ao buscar o parâmetro de consulta');
        const data = await response.json();
        setQueryParam(data.query_param);
      }
    } catch (err) {
      setParamError('Erro ao obter o parâmetro de consulta');
      setQueryParam('');
    }
  };

  const handleConsultaChange = (e) => {
    const selected = e.target.value;
    setSelectedConsulta(selected);
    fetchQueryParam(selected);
  };

  // Função para converter JSON em HTML formatado e colorido
  const jsonToHtml = (jsonData) => {
    let html = '<div class="json-container"><ul>';
    for (const key in jsonData) {
      if (typeof jsonData[key] === 'object' && jsonData[key] !== null) {
        html += `
          <li>
            <div class="json-item">
              <strong class="json-key">${key}</strong>
              <div class="json-nested">${jsonToHtml(jsonData[key])}</div>
            </div>
          </li>`;
      } else {
        html += `
          <li>
            <div class="json-item">
              <strong class="json-key">${key}</strong>: 
              <span class="json-value">${jsonData[key]}</span>
            </div>
          </li>`;
      }
    }
    html += '</ul></div>';
    return html;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);
    setResult(null);

    const url = `https://homolog-panel.zepesquisa.com.br/api/${selectedConsulta}/`;

    try {
      const response = await authFetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.error || 'Erro ao realizar a consulta');
      } else {
        if (selectedConsulta === specificEndpoint) {
          const result = await response.text();
          const newWindow = window.open();
          newWindow.document.write(result);
          newWindow.document.close();
        } else {
          const result = await response.json();
          const transformedHtml = jsonToHtml(result);
          setResult(transformedHtml);
        }
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleVoltar = () => {
    setSelectedConsulta('');
    setQuery('');
    setError(null);
    setParamError(null);
    setQueryParam('');
    setResult(null);
  };

  // Função para salvar o conteúdo como PDF, com quebras de página automáticas
  const handleSaveAsPDF = () => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a4',
    });
  
    // Define o título do PDF
    doc.setFontSize(12);
    doc.setTextColor(40, 40, 40);
    doc.text('Resultado da Consulta', 30, 40);
  
    // Oculta o botão "Salvar como PDF" para não aparecer no PDF
    const resultContainer = document.querySelector('.result-container');
    resultContainer.querySelector('.save-pdf-btn').style.display = 'none'; // Oculta o botão temporariamente
  
    // Usa html2canvas para capturar a imagem da página corretamente
    html2canvas(resultContainer, {
      scale: 1.5,
      useCORS: true,
      logging: false,
      width: resultContainer.scrollWidth, // Captura o tamanho total
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 500; // Largura da imagem no PDF
      const pageHeight = doc.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 60;

      // Adiciona a imagem capturada no PDF
      doc.addImage(imgData, 'PNG', 30, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - 60;

      // Adiciona novas páginas se o conteúdo exceder a altura da página
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 30, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Adiciona rodapé com numeração de páginas
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(8);
        doc.setTextColor(150);
        doc.text(`Página ${i} de ${pageCount}`, doc.internal.pageSize.getWidth() - 60, doc.internal.pageSize.getHeight() - 30);
      }

      // Exibe o botão novamente após gerar o PDF
      resultContainer.querySelector('.save-pdf-btn').style.display = 'block';

      doc.save('resultado_consulta.pdf');
    });
  };

  return (
    <div className="consultar-container">
      <h2>Consultar</h2>
      {loading && <div>Carregando...</div>}
      {!loading && error && (
        <div className="error-container">
          <div className="error-message">Erro: {error}</div>
          <button onClick={handleVoltar} className="voltar-btn">Voltar</button>
        </div>
      )}
      {!loading && !error && (
        <>
          <form onSubmit={handleSubmit} className="recarga-form">
            <label htmlFor="consulta">Escolha a consulta:</label>
            <select
              id="consulta"
              value={selectedConsulta}
              onChange={handleConsultaChange}
              required
            >
              <option value="" disabled>Selecione uma consulta</option>
              {consultas.length > 0 ? (
                consultas.map((consulta, index) => (
                  <option key={index} value={consulta}>
                    {consulta.replace(/-/g, ' ')}
                  </option>
                ))
              ) : (
                <option>Nenhuma consulta disponível</option>
              )}
            </select>
            {paramError && <p className="error-message">{paramError}</p>}
            <label htmlFor="parametroConsulta">Parâmetro de Consulta:</label>
            <input type="text" id="parametroConsulta" value={queryParam} readOnly />
            <label htmlFor="query">Digite o valor para a consulta:</label>
            <input
              type="text"
              id="query"
              placeholder="Ex: CPF, CNPJ, Nome, etc..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              required
            />
            <button type="submit" className="generate-pix-btn" disabled={submitting}>
              {submitting ? 'Carregando...' : 'Consultar'}
            </button>
          </form>
          {result && (
            <div className="result-container">
              <h3>Resultado da Consulta</h3>
              <div dangerouslySetInnerHTML={{ __html: result }} />
              <div className="result-buttons">
                <button onClick={handleSaveAsPDF} className="save-pdf-btn">Salvar como PDF</button>
                <button onClick={handleVoltar} className="voltar-btn">Voltar</button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ConsultarPage;
